import { SearchTermProvider } from '@ep/utils/useSearchTerm';
import theme from '@ep/theme';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';

export const wrapRootElement = ({ element }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme.muiTheme}>
        <SearchTermProvider>{element}</SearchTermProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
