import { createTheme } from '@mui/material/styles';
import colors from './colors';
import radius from './radius';
import typography from './typography';

const theme = createTheme();
const muiTheme = createTheme({
  palette: {
    primary: {
      light: colors.lightBlue,
      main: colors.mediumBlue,
      dark: colors.darkBlue,
    },
    text: {
      primary: colors.darkBlue,
    },
  },
  typography: {
    fontFamily: typography.lightFont,
    fontWeightRegular: 400,
  },
  shape: {
    borderRadius: 3,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          display: 'flex',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          backgroundColor: colors.lightGray,
          border: `1px solid ${colors.lightGray}`,
          borderRadius: 3,
          fontSize: typography.toRem(18),
          caretColor: colors.mediumBlue,
          transition: theme.transitions.create(
            ['border-color', 'background-color'],
            {
              duration: theme.transitions.duration.shorter,
              easing: theme.transitions.easing.easeOut,
            },
          ),
          '&:hover': {
            backgroundColor: colors.gray,
            borderColor: colors.gray,
            '@media (hover: none)': {
              backgroundColor: colors.white,
            },
          },
          '&$focused': {
            backgroundColor: colors.lightGray,
            borderColor: colors.mediumBlue,
          },
          '&$error': {
            borderColor: colors.mediumRed,
          },
          '&$disabled': {
            borderColor: colors.mediumGray,
          },
        },
        input: {
          padding: '0 16px',
          height: 68,
          '&$disabled': {
            cursor: 'not-allowed',
          },
        },
        multiline: {
          padding: '24px 16px',
        },
        underline: {
          '&:before, &:after': {
            display: 'none',
          },
        },
        adornedStart: {
          paddingLeft: 16,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.darkGray,
          '&$disabled': {
            color: colors.mediumGray,
          },
        },
        filled: {
          transform: 'translate(16px, 26px) scale(1)',
          fontSize: typography.toRem(18),
          '&$shrink': {
            transform: 'translate(16px, 8px) scale(0.667)',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        filled: {
          '&$positionStart:not($hiddenLabel)': {
            marginTop: -4,
          },
        },
        positionStart: {
          marginRight: 16,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
          marginTop: 4,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
          border: `1px solid ${colors.lightGray}`,
          borderRadius: radius.small,
          backgroundColor: colors.lightGray,
          padding: 0,
          transition: theme.transitions.create(
            ['border-color', 'background-color'],
            {
              duration: theme.transitions.duration.shorter,
              easing: theme.transitions.easing.easeOut,
            },
          ),
          '& path': {
            stroke: colors.mediumBlue,
          },
          '&$checked': {
            borderColor: colors.mediumBlue,
          },
          '&$disabled': {
            backgroundColor: colors.gray,
            borderColor: colors.mediumGray,
            '& path': {
              stroke: colors.mediumGray,
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'flex',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          '& + &': {
            marginTop: 4,
          },
          '&$disabled': {
            cursor: 'not-allowed',
          },
        },
        label: {
          marginLeft: 16,
          fontSize: typography.toRem(18),
          lineHeight: 1,
          '&$disabled': {
            color: colors.mediumGray,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: 8,
          color: colors.mediumGray,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
          border: `2px solid ${colors.lightGray}`,
          borderRadius: 16,
          backgroundColor: colors.lightGray,
          padding: 0,
          transition: theme.transitions.create(
            ['border-color', 'background-color'],
            {
              duration: theme.transitions.duration.shorter,
              easing: theme.transitions.easing.easeOut,
            },
          ),
          '& circle': {
            fill: colors.mediumBlue,
          },
          '&$checked': {
            borderColor: colors.mediumBlue,
          },
          '&$disabled': {
            backgroundColor: colors.gray,
            borderColor: colors.mediumGray,
            '& circle': {
              fill: colors.mediumGray,
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: colors.darkBlue,
        },
        iconFilled: {
          top: 'calc(50% - 7px)',
          right: 16,
        },
        // selectMenu: {
        //   display: 'flex',
        //   alignItems: 'center',
        //   height: 68,
        //   paddingRight: '56px !important',
        // },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: typography.toRem(18),
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: colors.gray,
          '&:not(:last-child)': {
            marginBottom: '0.5rem',
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            marginTop: 0,
          },
        },
        rounded: {
          borderRadius: 3,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 70,
          '&$expanded': {
            minHeight: 70,
          },
        },
        content: {
          margin: 0,
          color: colors.darkBlue,
          fontFamily: typography.boldFont,
          fontWeight: 'bold',
          '&$expanded': {
            margin: 0,
          },
        },
        // expandIcon: {
        //   color: colors.darkBlue,
        // },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0 0 0.5rem',
          flexDirection: 'column',
        },
      },
    },
  },
});

export default muiTheme;
