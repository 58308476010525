const breakpoints = {
  optimalMobile: 375,
  tablet: 540,
  optimalTablet: 768,
  desktop: 1024,
  navDrawerBreak: 1200,
  smallDesktop: 1200,
  desktopMenuBreak: 1170,
  optimalDesktop: 1440,
  maximumDesktop: 5760,
} as const;

export default breakpoints;
