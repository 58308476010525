module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://us-west-2.cdn.hygraph.com/content/cjzd5zuav123801f620sq3za3/master","headers":{"Authorization":"Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MTQyOTE4NDcsImF1ZCI6WyJodHRwczovL2FwaS11cy13ZXN0LTIuZ3JhcGhjbXMuY29tL3YyL2NqemQ1enVhdjEyMzgwMWY2MjBzcTN6YTMvbWFzdGVyIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiNDY5MGNhZTEtNjM3Zi00NGM3LTk2NTgtNjNjNzQ0NTMyNDkyIiwianRpIjoiY2tsbGZvcDlvc3kwNTAxeHJjZjljaGxzYiJ9.2WkO7TempnBQreMRqUBZ1iF2S53VbKxGmF3_xyuQoAriPTLOHJPwKI47cHjJ4jd8D-V09BS0UqEF0s4R8aqNhgqlB5tCz97DlmEXiXWmfIh3JjRNhGQjG86auZvZoSl1aWdnxHoxcVSCJu3_8D1W4_3qHd5ksivjZYnSKAbyTIc0V14OTMV-52eQzzzcXmmrk3GyKnoUbmSJOv-OU5dQnw_qUKn7hZUzOUaoP7p83x40aLSNym8t5mTQEaWTwQHDU-2KPHMgz8v2lKxWwHLL34Dc6JqN9WGHXSsSGaiyJwHXqKiHX3o2Y2hVmylPSc2MzuoQF0LUguqBdUhKLBdrnUy3cC9b4dL_xKFWWDPFZ5LjHfs8S2u1JZ-KhjZM0Xr2Hf0uYBT_sUikopMnwqcJWIl6COQ0fIU_vZ6YrMaUXLwmk0kq8pYKIbOZG2yfxNnaMMWJyL90MiZ0zn5V-pc0a-1kM-OrHNW_OdHCszGIo_EalocwTky2jwo5Wed8olaK9TUiUV5ke3qZUowTu3IeLfVDdUv0CY0RbEvrLs6fhbPg6bop22fv_R4OA-lrfHaAD8uB1YOZYJqMDfvLxgv0ixR9cyZNIoWAlZv-Syl21Uu3El8G2E-TvHvxu1Nm4uZrW13bMRWUkEmypKpEnjqd2P5l0CALhqCiNkG8_C0s14Q"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Entertainment Partners","short_name":"EP","start_url":"/","background_color":"#ffffff","theme_color":"#00273f","display":"minimal-ui","icon":"/home/node/app/src/images/favicon-ocean-blue.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"355786dfdd3ccafbba13e0b014d466b0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WDQTFXT","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MFDFVQQ","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
