const colors = {
  epLinkBlue: '#0054A8',
  epLinkBlueShades: ['#0054A8', '#0054A8', '#0054A8'],

  epPurple: '#D4DEF7',
  epPurpleShades: ['#D4DEF7', '#D4DEF7', '#D4DEF7'],

  duskBlue: '#10457a',
  duskBlueShades: ['#10457a', '#10457a', '#10457a'],

  oceanBlue: '#1474d4',
  oceanBlueShades: ['#1474d4', '#1474d4', '#1474d4'],

  skyBlue: '#06b6ed',
  skyBlueShades: ['#06b6ed', '#06b6ed', '#06b6ed'],

  mantisGreen: '#78b75e',
  mantisGreenShades: ['#78b75e', '#78b75e', '#78b75e'],

  darkBlue: '#00273f',
  darkBlueShades: ['#2d566f', '#6d8898', '#b4d0e1'],

  mediumBlue: '#0073bb',
  mediumBlueShades: ['#0086d9', '#75c9ff', '#a6ddff'],

  lightBlue: '#2ec4f3',
  lightBlueShades: ['#6ccbed', '#b9edff', '#d2f3ff'],

  darkPurple: '#403085',
  darkPurpleShades: ['#7a6faa', '#b3adcf', '#edebf4'],

  mediumPurple: '#b880b6',
  mediumPurpleShades: ['#d1a6cf', '#ead1f1', '#f6ebf6'],

  mediumRed: '#ec4624',
  mediumRedShades: ['#f0673b', '#fbb8ab', '#ffeeea'],

  lightRed: '#f0673b',

  darkGreen: '#133e2a',
  darkGreenShades: ['#5e786a', '#a4b2ab', '#e8ecea'],

  mediumGreen: '#2d8b43',
  mediumGreenShades: ['#73af7d', '#b1d1b5', '#edf5ee'],

  gold: '#916800',
  goldShades: ['#e6b95e', '#f7e5bf', '#fcf1da'],

  yellow: '#f7cf1d',
  yellowShades: ['#ffe365', '#fff1b4', '#fff9e0'],

  black: '#000',
  epBlack: '#232425',
  almostBlack: '#333',
  darkGray: '#5c5c5c',
  mediumGray: '#aaa',
  gray: '#e5e5e5',
  lightGray: '#f5f5f5',
  white: '#ffffff',

  white85: 'rgba(255, 255, 255, 0.85)',
  mediumBlue85: 'rgba(20,116,212,0.85)',

  footerNav: '#5c5c5c',
  bannerGreen: '#d9eea5',
  bannerYellow: '#fff3c2',
  bannerBlue: '#bed2e9',
  mediaKitBlue: '#2ec4f3',
  backgroundBlue: '#e9f2fb',
  testimonialQuoteBackground: '#e1f4fe',
  testimonialQuote: '#40beee',
  testimonialQuote2: '#415779',
  testimonialQuote3: '#89bf7b',
  goldOverDarkBackground: '#edaa00',
  newsHeroBackground: '#b98a2e',
} as const;

export default colors;
