import debounce from 'lodash.debounce';
import React, { useContext, useEffect, useRef, useState } from 'react';

interface SearchTermState {
  term: string;
  debouncedTerm: string;
}

interface SearchTermContextType extends SearchTermState {
  setTerm: (term: string) => void;
}

const SearchTermContext = React.createContext<SearchTermContextType>({
  term: '',
  debouncedTerm: '',
  setTerm: () => {},
});

export interface SearchTermProviderProps {
  children?: React.ReactNode;
}

export function SearchTermProvider({ children }: SearchTermProviderProps) {
  const [state, setState] = useState({
    term: '',
    debouncedTerm: '',
  });

  const latestStateRef = useRef(state);
  latestStateRef.current = state;

  const setDebouncedTermRef = useRef<(debouncedTerm: string) => void>(() => {});
  useEffect(() => {
    setDebouncedTermRef.current = debounce(
      (debouncedTerm: string) =>
        setState({ ...latestStateRef.current, debouncedTerm }),
      500,
    );
  }, [setState]);

  function setTerm(term: string) {
    setState({ ...state, term });
    setDebouncedTermRef.current(term);
  }

  return (
    <SearchTermContext.Provider value={{ ...state, setTerm }}>
      {children}
    </SearchTermContext.Provider>
  );
}

function useSearchTerm() {
  return useContext(SearchTermContext);
}

export default useSearchTerm;
