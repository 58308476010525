import breakpoints from './breakpoints';

const { desktop, tablet, optimalDesktop, desktopMenuBreak } = breakpoints;

const mediaQueries = {
  mobile: `@media (max-width: ${tablet - 1}px)`,
  mobileAndTablet: `@media (max-width: ${desktop - 1}px)`,
  tablet: `@media (min-width: ${tablet}px) and (max-width: ${desktop - 1}px)`,
  tabletAndDesktop: `@media (min-width: ${tablet}px)`,
  desktop: `@media (min-width: ${desktop}px)`,
  desktopMenuBreak: `@media (max-width: ${desktopMenuBreak}px)`,
  optimalDesktop: `@media (max-width: ${optimalDesktop}px)`
} as const;

export default mediaQueries;
