// import { createStyles } from '@mui/material/styles';
import breakpoints from './breakpoints';
import colors from './colors';
import mediaQueries from './mediaQueries';
import muiTheme from './muiTheme';
import radius from './radius';
import shadows from './shadows';
import typography from './typography';
import zIndex from './zIndex';

const theme = {
  breakpoints,
  colors,
  mediaQueries,
  muiTheme,
  radius,
  shadows,
  transitions: muiTheme.transitions,
  typography,
  zIndex,
} as const;

export default theme;
